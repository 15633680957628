.active {
  visibility: visible !important;
}

.win {
  background: #07d30741;
}

.player {
  color: red;
  font-weight: 600;
}

.box, .container {
  place-items: center;
  display: grid;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  font-family: Jost, sans-serif;
}

button, input {
  font-family: inherit;
}

.container {
  background: radial-gradient(328px at 2.9% 15%, #bfe0fb 0%, #e8e9fb 25.8%, #fceffa 50.8%, #eafbfb 77.6%, #f0fbf4 100.7%);
  min-width: 100vw;
  min-height: 100vh;
}

.heading {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 2rem;
  font-weight: 400;
  position: relative;
}

.heading:before {
  content: "O";
  color: red;
  font-weight: 500;
  position: absolute;
  left: -2rem;
}

.heading:after {
  content: "X";
  color: red;
  font-weight: 500;
  position: absolute;
  right: -2rem;
}

.status {
  color: #333;
  text-align: center;
  background-color: #fffffff3;
  border: 1px solid #333;
  border-radius: 1rem;
  padding: .5rem 3rem;
  font-weight: 500;
}

.game-grid {
  aspect-ratio: 1;
  background: linear-gradient(145deg, #e6e6e6, #fff);
  border: 1px solid #fffffff3;
  border-radius: 1rem;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  max-width: 20rem;
  padding: 2rem;
  display: grid;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #fff;
}

.box {
  aspect-ratio: 1;
  cursor: pointer;
  color: #333;
  border: 1px solid #1615152a;
  width: 100%;
  font-size: 2rem;
  font-weight: 500;
}

.btn-reset {
  cursor: pointer;
  visibility: hidden;
  background: #fffffff3;
  border: 1px solid #333;
  border-radius: 1rem;
  padding: .5rem 2rem;
}

@media screen and (width <= 481px) {
  .heading {
    font-size: 1.5rem;
  }

  .game-grid {
    max-width: 15rem;
  }

  .box {
    font-size: 1.5rem;
  }
}
/*# sourceMappingURL=index.49eef21b.css.map */
