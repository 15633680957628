/* ----- Utlity Class ----- */
.active {
  visibility: visible !important;
}

.win {
  background: rgba(7, 211, 7, 0.256);
}

.player {
  color: red;
  font-weight: 600;
}
